@keyframes draw {
  100% {
      stroke-dashoffset: 0;
  }
}
@keyframes fillIn {
  100% {
      fill: rgba(white, 1);
      stroke: rgba(white, 0);
  }
}

@keyframes fadeIn {
  100% {
      opacity: 1;
  }
}

.intro{
  background: url('./../../images/source.gif') 100% 100% no-repeat;
  background-size: cover;

  min-height: 500px;
  height: calc(100vh - 100px);
  @include md{
    height: calc(100vh - 40px);
  }
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:after{
    @include lg{
      position: absolute;
      display: block;
      content: '';
      bottom: -300px;
      left: -45px;
      width: 0; 
      height: 0; 
      transform: rotate(45deg);
      border-top: 300px solid transparent;
      border-bottom: 300px solid transparent;
      border-right: 300px solid black;
      z-index: 1;
    }
}
  .logo{
    width: calc(100vw * .8);
    @include lg {
      width: calc(100vw * .6);
    }
    backface-visibility: hidden;
    perspective: 1000;
    transform: translateZ(0);
    animation: draw 3s forwards ease-in 1s, fillIn .3s forwards ease-in 2s;
    fill: rgba(white, .0);
    stroke: rgba(white, 1);
    stroke-width: 1;
    stroke-dasharray: 5288.302734375;
    stroke-dashoffset: 5288.302734375;
  }
  .scroll-down{
    position: absolute;
    bottom: -10px;
    width: 100px;
    opacity: 0;
    animation: fadeIn 1s forwards ease-in 10s;
  }
}
.section-grid{
  padding: 50px 0;
  @include lg{
    padding: 100px 0;
    display: grid;
  }
  p{
    font-size: 11pt;
    line-height: 1.5em;
  }
  &.album{
    text-align: center;
    @include lg {
      min-height: calc(100vh - 40px);
      grid-template-columns:
        1fr
        minmax(40%, 500px)
        minmax(auto, 60ch)
        1fr;
    }
    .album-cover{
      margin: 30px 0 10px;
      @include md{
      }
      align-self: top;
      transform: rotate(10deg);
      grid-column: 2;
      width:100%;
      max-width: 550px;
      z-index: 2;
      // box-shadow: 0 14px 28px rgba(0,0,0,0.25);
      box-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12)
      ;
    }
    .album-content{
      color: white;
      align-self: center;
      text-align: left;
      grid-column: 3;
      padding: 40px;
      @include md{
        padding: 50px;
      }
      @include lg{
        padding: 0 50px;
        border-left: 3px rgba(black, .2) dashed;
      }
      p:last-of-type{
        margin-bottom: 40px;
      }
      .controls{
        padding: 20px 0 0;
        text-align: center;
        @include md{
          text-align: left;
        }
        a{
          display: inline-block;
          padding: 0 40px 0 0;
          font-family: 'Montserrat', sans-serif;
          text-transform: uppercase;
          text-decoration: none;
          color: white;
          opacity: .6;
          font-size: 16pt;
          font-weight: 800;
          transition: all 0.3s $niceBezier;
          will-change: opacity, padding;
          span{
            font-family: 'Libre Baskerville', serif;
            text-transform: none;
            font-weight: normal;
            font-style: oblique;
            font-size: 12pt;
          }
          &.buy{
            padding: 0 55px 0 0;
            background: url('./../../images/bandcamp-logo.svg') center right / 50px no-repeat;
          }
          &:hover, &:active{
            opacity: 1;
            padding: 0 65px 0 0;
          }
        }
      }
      h2{
        font-size: 36pt;
        line-height: 36pt;
        margin: 10px 0 20px;
        text-transform: uppercase;
      }
      .catalog-no{
        display: block;
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 9pt;
        color: rgba(white, .4);
      }
      .album-title{
        font-family: 'Libre Baskerville', serif;
        font-style: italic;
        font-size: 20pt;
        margin:0 0 1.5em;
        font-weight: normal;
      }
    }
    &.pulp{
      background: #385d51;
      position: relative;
      &:before{
        @include lg {
          position: absolute;
          display: block;
          content: '';
          top: -200px;
          right: -45px;
          width: 0; 
          height: 0; 
          transform: rotate(45deg);
          border-top: 200px solid transparent;
          border-bottom: 200px solid transparent;
          border-left: 200px solid #385d51;
          z-index: 1;
        }
    }
      // background: rgb(204,195,122);
      // background: linear-gradient(43deg, rgba(204,195,122,1) 0%, rgba(228,220,151,1) 24%); 
    }
    &.hagioscope{
      background: #333;
      position: relative;
      &:after{
        @include lg {
          position: absolute;
          display: block;
          content: '';
          bottom: -300px;
          left: -45px;
          width: 0; 
          height: 0; 
          transform: rotate(45deg);
          border-top: 300px solid transparent;
          border-bottom: 300px solid transparent;
          border-right: 300px solid #333;
          z-index: 1;
        }
      }
    }
  }
  &.contact{
    background-color: #222;
    @include lg{
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
    color: white;
    h2{
      font-size: 25pt;
      margin: 0;
      text-transform: uppercase;
    }
    .addresses{
      text-align: center;
      padding: 30px 50px;
      @include lg {
        text-align: left;
        width: 40%;
        max-width: 550px;
        padding: 0 50px;
      }
      h3{
        font-family: 'Libre Baskerville', serif;
        font-style: oblique;
        font-weight: normal;
        margin-bottom: 5px;
        color: rgba(white, .7);
      }
      a {
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
        color: white;
        font-weight: 800;
        display: inline-block;
        padding-bottom: 5px;
        text-decoration: none;
        font-size: 18pt;
        span{
          font-weight: normal;
        }
      }
    }
    .mailing-list{
      text-align: center;
      padding: 30px 40px;
      @include lg {
        text-align: left;
        width: 40%;
        max-width: 550px;
        padding: 0 50px;
      }
      form{
        padding: 20px 0;
        input, button{
          display: inline-block;
          padding: 10px;
          width: 100%;
          @include md{
            width: auto;
          }
          font-size: 18px;
          border-radius: 0;
          -webkit-appearance: none;
          border: 1px solid transparent;  
          font-family: 'Montserrat', sans-serif;  
        }
        button{
          background: #385d51;
          text-transform: uppercase;
          color: rgba(white, .7);
          &:hover{
            background: lighten(#385d51, 10%);
          }
        }
      }
    }
  }
}

footer{
  width: 100vw;
  padding: 20px;
  background: #333;
  text-align: center;
  span{
    opacity: .4;
    display: block;
    font-size: 10pt;
    transform: rotate(180deg);
    color: white;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;
  }
}