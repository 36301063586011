@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700|Montserrat:400,800&display=swap');

html, body{
  font-family: 'Libre Baskerville', serif;
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: greyscale;
  }
  
}



h1, h2, h3, h4{
   font-family: 'Montserrat', sans-serif;
   font-weight: 800;
}