html, body {
  height: auto !important;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: black;
}

html{
  box-sizing: border-box;
}

*, *:before, *:after{
  box-sizing: inherit
}

@import 'helpers/vars';
@import 'helpers/mixins';

@import 'elements/typography';

@import 'modules/containers';

